<template>
    <div>
        <form enctype="multipart/form-data">
            <md-steppers @md-changed="stepChanged" md-vertical class="md-elevation-4" :md-active-step="step">
                <md-step id="general" md-label="General">  
                        
                    <CRow>
                        <CCol sm="12" md="12" lg="4" xl="4">
                            <CRow>
                                <CCol sm="6">
                                    <md-field :class="getValidationClass('name')">
                                        <label for="name">Name</label>
                                        <md-input name="name" id="name" autocomplete="given-name" v-model="driver.general.name" :disabled="sending" />
                                        <span class="md-error" v-if="!$v.driver.general.name.required">Name is required</span>
                                        
                                    </md-field>
                                </CCol>
                                <CCol sm="6">
                                    <md-field :class="getValidationClass('lastname')">
                                        <label for="last-name">Last Name</label>
                                        <md-input name="last-name" id="last-name" autocomplete="given-name" v-model="driver.general.lastname" :disabled="sending" />
                                        <span class="md-error" v-if="!$v.driver.general.lastname.required">Lastname is required</span>
                                        
                                    </md-field>
                                </CCol>
                            </CRow>
                            <md-field :class="getValidationClass('email')">
                                <label for="email">Email</label>
                                <md-input type="email" name="email" id="email" autocomplete="off" v-model="driver.general.email" :disabled="sending" />
                                <span class="md-error" v-if="!$v.driver.general.email.required">The email is required</span>
                                <span class="md-error" v-else-if="!$v.driver.general.email.email">Invalid email</span>
                            </md-field>
                            <md-field :class="getValidationClass('displayName')">
                                <label for="display-name">Display Name</label>
                                <md-input name="display-name" id="display-name" autocomplete="given-name" v-model="driver.general.displayName" :disabled="sending" />
                                <span class="md-error" v-if="!$v.driver.general.displayName.required">Display name is required</span>
                                <span class="md-error" v-else-if="!$v.driver.general.displayName.minlength">Invalid first name</span>
                            </md-field>
                            <md-field :class="getValidationClass('password')">
                                <label for="password">Password</label>
                                <md-input type="password" name="password" id="password" autocomplete="given-name" v-model="driver.general.password" :disabled="sending" />
                                <span class="md-error" v-if="!$v.driver.general.password.required">Password is required</span>
                            </md-field>
                        </CCol>
                    </CRow>
                
                        
                    <md-button class="md-primary md-raised" @click="nextOrSave">Next step</md-button>
                    
                </md-step>
                <md-step id="dmv" md-label="DMV">
                    <CRow>
                        <CCol sm="12" md="12" lg="4" xl="4">
                            <md-field :class="getValidationClass('dmv.driverLicense')">
                                <md-icon>assignment_id</md-icon>
                                <label for="driver-license">DMV driver license</label>
                                <md-input name="driver-license" id="driver-license" autocomplete="given-name" v-model="driver.dmv.driverLicense" :disabled="sending" />
                                <span class="md-error" v-if="!$v.driver.dmv.driverLicense.required">Driver license is required</span>
                            </md-field>


                            <md-datepicker :class="getValidationClass('dmv.driverLicenseExpiration')" name="driver-license" id="driver-license-expiration" v-model="driver.dmv.driverLicenseExpiration" :disabled="sending">
                                <label for="driver-license-expiration">DMV driver license expiration</label>
                                <span class="md-error" v-if="!$v.driver.dmv.driverLicenseExpiration.required">Driver license expiration is required</span>
                            </md-datepicker>
                            
                            <md-field :class="getValidationClass('dmv.driverLicensePicture')">
                                <label for="driver-license-picture">DMV driver license picture</label>
                                <md-file name="driver-license" id="driver-license-picture" v-model="driver.dmv.driverLicensePicture" :disabled="sending" @change="imageChanged($event, 'dmv')"/>
                                <span class="md-error" v-if="!$v.driver.dmv.driverLicensePicture.required">Driver license picture is required</span>
                            </md-field>
                            <md-button class="md-primary md-raised" @click="nextOrSave">Next step</md-button>
                            
                            
                        </CCol>
                        <CCol sm="12" md="12" lg="4" xl="4">
                            <md-card class="license-preview">   
                                <md-card-media-cover>
                                    <md-card-media>
                                        <img :src="this.driver.dmv.driverLicensePreview ? this.driver.dmv.driverLicensePreview : '/img/dmv-license-sample.png'" alt="">
                                    </md-card-media>
                                    
                                    <md-card-header>
                                        <span class="md-title">{{ driver.dmv.driverLicense ? driver.dmv.driverLicense : 'DMV driver license'}}</span>
                                        <span class="md-subhead" v-if="driver.dmv.driverLicenseExpiration">{{ driver.dmv.driverLicenseExpiration | moment("MMMM Do YYYY") }}</span>
                                        <span class="md-subhead" v-else>Expiration date</span>
                                    </md-card-header>

                                    <!--md-card-actions>
                                        <md-button>Neither this</md-button>
                                        <md-button>Or this</md-button>
                                    </md-card-actions-->
                                   
                                </md-card-media-cover>
                            </md-card>
                        </CCol>
                    </CRow>

                    
                </md-step> 
                <md-step id="tlc" md-label="TLC">
                    <CRow>
                        <CCol sm="12" md="12" lg="4" xl="4">
                            <md-field :class="getValidationClass('tlc.driverLicense')">
                                <md-icon>assignment_id</md-icon>
                                <label for="tlc-driver-license">TLC driver license</label>
                                
                                <md-input name="tlc-driver-license" id="tlc-driver-license" autocomplete="given-name" v-model="driver.tlc.driverLicense" :disabled="sending" />
                                <span class="md-error" v-if="!$v.driver.tlc.driverLicense.required">TLC Driver license is required</span>
                            </md-field>


                            <md-datepicker :class="getValidationClass('tlc.driverLicenseExpiration')" name="tlc-driver-license-expiration" id="tlc-driver-license-expiration" v-model="driver.tlc.driverLicenseExpiration" :disabled="sending">
                                <label for="tlc-driver-license-expiration">TLC driver license expiration</label>
                                <span class="md-error" v-if="!$v.driver.tlc.driverLicenseExpiration.required">TLC Driver license expiration is required</span>
                            </md-datepicker>

                            

                            <md-field :class="getValidationClass('tlc.driverLicensePicture')">
                                <label for="tlc-driver-license-picture">TLC driver license picture</label>
                                <md-file name="tlc-driver-license-picture" id="tlc-driver-license-picture" v-model="driver.tlc.driverLicensePicture" :disabled="sending" @change="imageChanged($event, 'tlc')"/>
                                <span class="md-error" v-if="!$v.driver.tlc.driverLicensePicture.required">TLC driver license picture is required</span>
                            </md-field>
                            <md-button class="md-primary md-raised" @click="nextOrSave">Save driver</md-button>

                            
                        </CCol>
                        <CCol sm="12" md="12" lg="4" xl="4">
                            <md-card class="license-preview">   
                                <md-card-media-cover>
                                    <md-card-media>
                                        <img :src="this.driver.tlc.driverLicensePreview ? this.driver.tlc.driverLicensePreview : '/img/dmv-license-sample.png'" alt="">
                                    </md-card-media>
                                    
                                    <md-card-header>
                                        <span class="md-title">{{ driver.tlc.driverLicense ? driver.tlc.driverLicense : 'DMV driver license'}}</span>
                                        <span class="md-subhead" v-if="driver.tlc.driverLicenseExpiration">{{ driver.tlc.driverLicenseExpiration | moment("MMMM Do YYYY") }}</span>
                                        <span class="md-subhead" v-else>Expiration date</span>
                                    </md-card-header>

                                    <!--md-card-actions>
                                        <md-button>Neither this</md-button>
                                        <md-button>Or this</md-button>
                                    </md-card-actions-->
                                   
                                </md-card-media-cover>
                            </md-card>
                        </CCol>
                    </CRow>
                </md-step> 
            
            </md-steppers>
        </form>
        <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
       
    </div>
</template>

<script>

import moment from 'moment'

import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    maxLength
  } from 'vuelidate/lib/validators'

import axios from "./../../helpers/axios"

export default {
  name: 'NewDriver',
  mixins: [validationMixin],
  data() {
    return {
     
      role: '',
      userSaved: false,
      sending: false,
      lastUser: null,
      step: "general",
      driver: {
          general: {
              name: null,
              lastname: null,
              email: null,
              displayName: null,
              password: null
          },
          dmv: {
              driverLicense: null,
              driverLicenseExpiration: Number(new Date()),
              driverLicensePicture: null,
              driverLicenseFile: null,
              driverLicenseUrl: null,
              driverLicenseUploadProgress : 0,
              driverLicensePreview: null
          },
          tlc: {
              driverLicense: null,
              driverLicenseExpiration: Number(new Date()),
              driverLicensePicture: null,
              driverLicenseFile: null,
              driverLicenseUrl: null,
              driverLicenseUploadProgress : 0,
              driverLicensePreview: null
          }

      }
      
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    } 
  },
  methods: {
    async uploadLicense(type) {
        return new Promise((resolve, reject) => {
             console.log("Uploading image ...");
            console.log(type);
            this.driver[type].driverLicenseUrl = null;        
            const storageRef = this.$firebase.storage().ref()
                                .child(`drivers/${this.driver.general.email}/${type}-driver-license/${this.driver[type].driverLicensePicture}`)
                                .put(this.driver[type].driverLicenseFile);
            storageRef.on(`state_changed`,
                snapshot => {
                    this.driver[type].driverLicenseUploadProgress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                }, 
                error => {
                    console.log(error.message)
                    reject(error);
                },
                async () => {
                    const imgURL = await storageRef.snapshot.ref.getDownloadURL();
                    console.log("uploaded image: " + imgURL);
                    this.driver[type].driverLicenseUrl = imgURL;
                    resolve();

                }
            );
        });
    },
    imageChanged(e, type) {
        
        this.driver[type].driverLicenseFile = e.target.files[0]
        this.driver[type].driverLicensePreview = URL.createObjectURL(e.target.files[0]);
       
    },
    nextOrSave(){
        console.log(this.step);
        console.log(this.$v);
        switch(this.step) {
            case 'general':
                
                if(this.validateStep(this.step)){
                    this.step = 'dmv'
                } else {
                    this.$v.driver.general.name.$touch();
                    this.$v.driver.general.lastname.$touch();
                    this.$v.driver.general.email.$touch();
                    this.$v.driver.general.displayName.$touch();
                    this.$v.driver.general.password.$touch();
                }
                break
            case 'dmv':
                if(this.validateStep(this.step)){
                    this.step = 'tlc'
                } else {
                    console.log(false);
                    this.$v.driver.dmv.driverLicense.$touch();
                    this.$v.driver.dmv.driverLicenseExpiration.$touch();
                    this.$v.driver.dmv.driverLicensePicture.$touch();
                }
                break
            case 'tlc':
                if(this.validateStep(this.step)){
                    // Save
                    this.saveUser();
                } else {
                    this.$v.driver.tlc.driverLicense.$touch();
                    this.$v.driver.tlc.driverLicenseExpiration.$touch();
                    this.$v.driver.tlc.driverLicensePicture.$touch();
                }
                break
        }
    },
    validateStep(step){
        switch(step) {
            case 'general':
                let name,lastname,email,displayName,password ;
                name = this.$v.driver.general.name.$invalid;
                lastname = this.$v.driver.general.lastname.$invalid;
                email = this.$v.driver.general.email.$invalid;
                displayName = this.$v.driver.general.displayName.$invalid;
                password = this.$v.driver.general.password.$invalid;
                return !name && !lastname && !email && !displayName && !password ? true : false ;
                
            case 'dmv':
                let driverLicense, driverLicenseExpiration, driverLicensePicture ;
                driverLicense = this.$v.driver.dmv.driverLicense.$invalid;
                driverLicenseExpiration = this.$v.driver.dmv.driverLicenseExpiration.$invalid;
                driverLicensePicture = this.$v.driver.dmv.driverLicensePicture.$invalid;
                return !driverLicense && !driverLicenseExpiration && !driverLicensePicture ? true : false ;
                
            case 'tlc':
                let tlcDriverLicense, tlcDriverLicenseExpiration, tlcDriverLicensePicture ;
                tlcDriverLicense = this.$v.driver.tlc.driverLicense.$invalid;
                tlcDriverLicenseExpiration = this.$v.driver.tlc.driverLicenseExpiration.$invalid;
                tlcDriverLicensePicture = this.$v.driver.tlc.driverLicensePicture.$invalid;
                return !tlcDriverLicense && !tlcDriverLicenseExpiration && !tlcDriverLicensePicture ? true : false ;
                
        }
    },
    stepChanged(id)
    {
        this.step = id;
    },
    getValidationClass (fieldName) {
        let field = null;
        //const field = this.$v[fieldName]
        if(
            fieldName == 'email' 
            || fieldName == 'displayName' 
            || fieldName == 'password' 
            || fieldName == 'name' 
            || fieldName == 'lastname' 
            ) {
            // General Step fields
            field = this.$v.driver.general[fieldName]
        } else if (
            fieldName == 'dmv.driverLicense' 
            || fieldName == 'dmv.driverLicenseExpiration' 
            || fieldName == 'dmv.driverLicensePicture' 
            ) {
            let fieldSplit = fieldName.split('dmv.')[1];
            field = this.$v.driver.dmv[fieldSplit]
        } else if (
            fieldName == 'tlc.driverLicense' 
            || fieldName == 'tlc.driverLicenseExpiration' 
            || fieldName == 'tlc.driverLicensePicture' 
        ) {
            let fieldSplit = fieldName.split('tlc.')[1];
            field = this.$v.driver.tlc[fieldSplit]
        }

        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    clearForm () {
        this.$v.$reset()
        this.driver = null;
        this.role = null
        
    },
    async saveUser () {
        this.sending = true
        await this.uploadLicense('dmv');
        await this.uploadLicense('tlc');
        this.createDriver()
        // Instead of this timeout, here you can call your API
        // window.setTimeout(() => {
        //     this.lastUser = `${this.form.firstName} ${this.form.lastName}`
        //     this.userSaved = true
        //     this.sending = false
        //     this.clearForm()
        // }, 1500)
    },
    validateUser () {
        this.$v.$touch()

        if (!this.$v.$invalid) {
            this.saveUser()
        }
    },
    async createDriver() {
      console.log('createDriver');
      
      try {
          ////
        let th = this ;
        let data = {
            name: this.driver.general.name,
            lastname: this.driver.general.lastname,
            displayName: this.driver.general.displayName, 
            password: this.driver.general.password, 
            email: this.driver.general.email, 
            ownerEmail: this.user.data.email,
            driver: {
                dmv: {
                    dmvDriverLicense: this.driver.dmv.driverLicense,
                    dmvDriverLicenseExpiration: this.driver.dmv.driverLicenseExpiration,
                    dmvDriverLicenseUrl: this.driver.dmv.driverLicenseUrl,
                },
                tlc: {
                    tlcDriverLicense: this.driver.tlc.driverLicense,
                    tlcDriverLicenseExpiration: this.driver.tlc.driverLicenseExpiration,
                    tlcDriverLicenseUrl: this.driver.tlc.driverLicenseUrl,
                }
            },
        }

       
       
        console.log(data)

        axios.post('drivers',data).then(response =>{
            console.log(response);
            th.sending = false
            th.$router.push({path: '/users/drivers'})
        }).catch(e => {
            console.log(e);
            th.sending = false
        }) 

        
       
       
      } catch (error) {
        console.log(error.message);
      }
      
      
    }

  },
  validations: {
    driver: {
        general: {
            name: {
                required
            },
            lastname: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(3)
            },
            displayName: {
                required,
                minLength: minLength(3)
            }
        },
        dmv: {
              driverLicense: {
                  required
              },
              driverLicenseExpiration: {
                  required
              },
              driverLicensePicture: {
                  required
              }
        },
        tlc: {
            driverLicense: {
                required
            },
            driverLicenseExpiration: {
                required
            },
            driverLicensePicture: {
                required
            }
        }
    }
  },
}
</script>